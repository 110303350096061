* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: Inter, Geneva, Helvetica, sans-serif;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

nav {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 100px;
  padding: 30px 25px;
}

.nav-text {
  margin: 0;
  margin-right: 30px;
  color: #0d86e9;
  font-family: Inter, Geneva, Helvetica, sans-serif;
}

.nav-logout {
  margin-left: auto; 
  margin-right: 30px;
}

.nav-logo {
  height: auto; 
  width: auto; 
  max-height: 100px;
  padding: 20px;
  margin-right: 80px;
}

.add-visit-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 20px;
  width: fit-content;
  background-color: #0d86e9;
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 15pt;
  padding: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
}

.visits-table-row {
  border-bottom: 1px solid rgb(189, 218, 250);
  height: 50px;
  margin-top: 0px;
}

.visits-table-header {
  padding: 20px;
  height: 50px;
  border-bottom: 1px solid rgb(189, 218, 250);
}

.visits-table-cell {
  padding: 20px;
  text-align: center;
}

.visits-table-header-cell {
  padding: 20px;
  text-align: center;
  font-size: 15pt;
}

.visits-table-button {
  margin: auto;
  height: 10px;
  width: fit-content;
  background-color: #0d86e9;
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
}

.visits-table-status-done {
  padding: 10px;
  text-align: center;
  background-color: rgb(53, 161, 53);
  color: white;
  border-radius: 5px;
}

.visits-table-status-inprogress {
  padding: 10px;
  text-align: center;
  background-color: rgb(229, 139, 5);
  color: white;
  border-radius: 5px;
}

.modal-close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
}

.modal-header {
  margin: auto;
  text-align: center;
  margin-top: 30px;
  font-size: 15pt;
}

.modal-form {
  margin: auto;
  text-align: center;
  margin-top: 0px;
}

.modal-label {
  display: block;
  margin-top: 10px;
}

.modal-input {
  width: 400px;
  height: 23px;
}

.choose-worker-header {
  margin: auto;
  text-align: center;
  margin-top: 30px;
  font-size: 15pt;
}

.add-worker-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  height: 30px;
  width: fit-content;
  background-color: #3f4b55;
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
}

.delete-worker-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  width: fit-content;
  background-color: #8e8e8e;
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
}

.choose-worker-block {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 100px;
}

.choose-worker-label {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.choose-worker-input {
  width: 200px;
  height: 30px;
}

.add-service-header {
  margin: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 15pt;
}

.add-button {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  height: 23px;
  width: fit-content;
  background-color: #3f4b55;
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
}

.draft-button {
  height: 50px;
  width: 100px;
  background-color: rgb(229, 139, 5);
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 5px;
  display: inline-block;
  align-items: center;
  color: #ffffff;
  border: none;
  float: left;
  margin-bottom: 100px;
}

.save-button {
  height: 50px;
  width: 100px;
  background-color: rgb(53, 161, 53);
  border-radius: 5px;
  font-family: Inter, Geneva, Helvetica, sans-serif;
  font-size: 10pt;
  padding: 5px;
  align-items: center;
  display: inline-block;
  color: #ffffff;
  border: none;
  float: right;
  margin-bottom: 100px;
}

.button-div {
  margin: auto;
  margin-bottom: 10px;
  display: block;
  width: 210px;
}

.add-visit-warning {
  margin: auto;
  display: block;
  text-align: center;
  font-weight: bold;
}

.div-row {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: repeat(2, auto);
  justify-content: space-around;
  grid-template-areas:
  "c a b"
}

.edit-visit-modal {
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 12px;
  position: absolute;
  width: 900px;
  top: 20px;
  left: calc(50% - 450px);
  bottom: 20px;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 250px);
  grid-template-rows: repeat(2, auto);
  justify-content: space-around;
  grid-template-areas:
  "a b"
}

.edit-worker-modal {
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 12px;
  position: absolute;
  width: 500px;
  top: 20px;
  bottom: 20px;
  overflow: auto;
  inset: 0px;
  padding: 40px;
  margin: auto;
  height: fit-content;
}

.choose-worker-div {
  grid-area: b;
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  text-align: center;
  border-radius: 12px;
  width: 300px;
  height: auto;
  margin: auto;
  margin-top: 30px;
  margin-right: 30px;
}

.add-visit-div {
  grid-area: a;
  grid-row: 1/3;
  margin: auto;
  border-radius: 12px;
  width: 300px;
  top: 20px;
  left: calc(50% - 400px);
  bottom: 20px;
  overflow: auto;
}

.price-div {
  grid-area: b;
  grid-row: 1;
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  text-align: center;
  border-radius: 12px;
  width: 300px;
  height: auto;
  margin: auto;
  margin-top: 30px;
  margin-right: 30px;
}

.price-header {
  margin: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 15pt;
}

.login-logo {
  margin: auto;
  display: block;
  height: auto; 
  width: auto; 
  max-height: 200px;
  padding: 20px;
}

.filter-show-button {
  margin: auto;
  margin-top: 30px;
  display: block;
}

.filter-div {
  /* grid-area: header; */
  margin: auto;
  display: flex;
  margin-top: 30px;
  justify-content: center;
}


.dashboard {
  display: grid;
  align-items: start;
  grid-template-columns: 3fr 1fr; /* Определение размеров колонок */
  grid-auto-rows: auto;
  gap: 3vh;
}

.visits-table {
  /* grid-area: main; */
  grid-column: 1;
  grid-row: 1 / span 2; 
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border-collapse: collapse;
}

.money-window {
  grid-column: 2;
  grid-row: 1;
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  text-align: center;
  border-radius: 12px;
  width: 300px;
  height: 520px;
  margin-top: 63vh;
}

.motivational-window {
  grid-column: 2;
  grid-row: 1;
  box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  text-align: center;
  border-radius: 12px;
  margin-top: 10vh;
  width: 300px;
  height: 400px;
}

.money-modal-header {
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
}

.mixed-payment-div {
  margin-bottom: 30px;
}

.bottom-modal-input {
  margin-bottom: 30px;
}